import React from 'react';

const MenuItem  = ({el}) => {

  console.log()
  return ( 
    <div className={`menu__box col-12 col-md-5 p-0 mt-4`}>
    <div className="menu__box-headline">
      <span className='box-title'>{el.title}</span>  {el.warning ? <span className='warning'>{el.warning}</span> : ""} <span className='box-price'>{el.price}</span>
    </div>
    <h3>

    </h3>
    <p className="mt-4">{el.description}</p>
    {el.additionalInfo ? <span className='additional-info'>{el.additionalInfo}</span> : ""}
    </div>
   );
}
 
export default MenuItem;