
import React from 'react'
import { menuButtons } from "../data/menu-buttons";
import { useEffect , useState } from "react";

const MenuButtons = ({catButtonHandler}) => {

  const [menuBtns , setMenuButtons] = useState([])
  const [activeName , setActive] = useState("all")
  useEffect(()=>{
    setMenuButtons(menuButtons)
   
  },[])

  return (
    <div className="categories">
      {
        menuBtns.map(category =>{
          return(
            <button key={category.name}
            
            name={category.name}
            className={`${category.className} ${activeName == category.name ? "active": ""}`}
            onClick={(e)=>{
              catButtonHandler(e.target.name)
              setActive(e.target.name)
            }}
          >
            {category.title}
          </button>
          )
        })
      }
    </div>
    
   );
}
 
export default MenuButtons;