import React from "react"
import { Link } from "gatsby"
import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Cta from "../components/cta"
import MenuItems from "../components/menu-items"
import MenuButtons from "../components/menu-buttons"
import { useState, useEffect } from "react"
import { menuItems } from "../data/menu"

const MenuPage = ()=> {
  const [clickedCategory , setCategory] = useState("")
  const [menu , setMenu] = useState([])
  const categoryHandler = (category) =>{
    setCategory(category)
  }
  useEffect(()=>{
    setMenu(menuItems)
  },[clickedCategory, menu])


    return (
      <Layout>
        <SEO
          title="Menu"
          description="Burgery, steki, pierogi ora wiele więcej"
        />
        <Header title="MENU" />
        <section className="menu">
          <div className="container ">
            <div className="row d-flex flex-column" style={{ margin: "auto" }}>
              <div className="categories">
                <MenuButtons catButtonHandler={categoryHandler}/>
              </div>
              <MenuItems category={clickedCategory} menuElements={menu}/>
            </div>
          </div>
        </section>

        <Cta />
      </Layout>
    )
  
}
export default MenuPage
