import React from "react"
import MenuItem from "./menu-box";

const MenuItems = ({category,  menuElements  }) => {

  const filterElement = menuElements.filter(item => {
    return item.category == category
  })
  
  const menu = filterElement[0]?.items.map(item =>{
    return(
      <MenuItem el={item}/>
    )
  })

  const allMenu = menuElements.map(el=>{
    const items = el.items.map(item =>{
      return(
        <MenuItem el={item}/>
      )
    })
    return(
      <>
      <h2 class="col-12 text-center mt-5  mb-5">{el.title}</h2>
      {items}
      </> 
     )
  })

  return (
    <div className={`menu__items  d-flex flex-wrap justify-content-between`}>
      {menu ? menu : allMenu}
    </div>
  )
}

export default MenuItems
