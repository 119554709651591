export const menuButtons = [
  {
    name:"all",
    title: "Wszystko",
    className: "all menu-button"
  },
  {
    name: "sandwich",
    title: "Kanapki",
    className: "sandwich menu-button"
  },
  {
    name: "burgers",
    title: "Burgery",
    className: "burgers menu-button"
  },
  {
    name: "steak",
    title: "Steki and BBQ",
    className: "steaks menu-button"
  },
  {
    name: "pierogi",
    title: "Pierogi",
    className: "pierogi menu-button"
  },
  {
    name: "soup",
    title: "Zupy",
    className: "soup menu-button"
  },
  {
    name: "kids",
    title: "Food Kids",
    className: "kids menu-button"
  },
  {
    name: "drinks",
    title: "Napoje",
    className: "drinks menu-button"
  },
  {
    name: "beer",
    title: "Piwo",
    className: "beer menu-button"
  },
]